<template>
  <b-modal centered ref="modal" title="إضافة دفع" hide-footer>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row cols="1">
          <!--  price -->
          <b-col cols="8">
            <b-form-group label="المبلغ" label-for="v-price">
              <validation-provider #default="{ errors }" name="المبلغ" rules="required">
                <b-form-input id="v-price" v-model.number="price" type="number" :state="errors.length > 0 ? false : null" placeholder=" المبلغ بالجنيه" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="النوع" label-for="v-type">
              <validation-provider #default="{ errors }" name="نوع الدفع" rules="required">
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="type == null ? 'نوع الدفع' : type"
                  v-model="type"
                  :state="errors.length > 0 ? false : null"
                  variant="outline-primary"
                >
                  <b-dropdown-item value="كاش" @click="type = 'كاش'">كاش</b-dropdown-item>
                  <b-dropdown-item value="شيك" @click="type = 'شيك'">شيك</b-dropdown-item>
                </b-dropdown>
                <small style="display: block" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="التاريخ" label-for="v-date">
              <validation-provider #default="{ errors }" name="التاريخ" rules="required">
                <b-form-datepicker
                  size="lg"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="date"
                  locale="ar"
                  placeholder="تاريخ"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="التفاصيل" label-for="v-details">
              <b-form-textarea rows="4" max-rows="4" size="lg" id="v-details" v-model="details"></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button :disabled="isValidating" v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="success" @click.prevent="validationForm">
              إضافه
            </b-button>
            <b-button
              :disabled="isValidating"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              style="margin-right: 15px"
              @click="hideModal"
            >
              رجوع
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormDatepicker,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple
  },
  data() {
    return {
      isValidating: false,
      locale: 'ar',
      required,
      price: null,
      date: null,
      details: '',
      type: null
    }
  },
  mounted() {
    localize(this.locale)
  },

  methods: {
    showModal() {
      this.resetForm()
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    resetForm() {
      this.price = null
      this.date = null
      this.details = ''
      this.type = null
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        this.isValidating = true
        if (success) {
          await this.AddPayment()
        }
        this.isValidating = false
      })
    },
    async AddPayment() {
      const payload = {
        companyId: this.$route.params.id,
        price: this.price,
        date: this.date,
        type: this.type,
        details: this.details
      }
      try {
        const response = await this.$store.dispatch('AddPayment', payload)
        if ((response.status = 200)) {
          //emit when success
          this.$emit('add-payment', payload.price)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الإضافة بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        }
        this.$refs.modal.hide()
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    }
  }
}
</script>
<style scoped>
::v-deep .dropdown-menu {
  width: 270px;
}
</style>
