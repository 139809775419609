<template>
  <b-card-code title="" style="margin-top: 0px">
    <company-card :id="id" :key="componentKey" @toggle="ToggleTables" :selectedInvoice="selectedInvoice" />
    <div v-if="toggleTables">
      <payments :key="componentKey" :id="id" />
    </div>
    <div v-else>
      <bills :key="componentKey" :companyName="company.userDisplayName" @update-invoice-status="invoiceStatusUpdated" />
    </div>
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
import companyCard from './company-card.vue'
import payments from './payments.vue'
import bills from './bills.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    'company-card': companyCard,
    bills: bills,
    payments: payments,
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker
  },

  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: ['id'],
  // created() {
  //   this.GetCompany(this.$route.params.id)
  // },
  data() {
    return {
      company: {},
      pageLength: 7,
      dir: false,
      codeBasic,
      toggleTables: false,
      componentKey: 0,
      selectedInvoice: {}
    }
  },
  methods: {
    async invoiceStatusUpdated(invoice) {
      this.selectedInvoice = invoice
    },
    async GetCompany(companyId) {
      try {
        const response = await this.$store.dispatch('GetCompany', companyId)
        if ((response.status = 200)) {
          this.company = response.data
          this.componentKey += 1
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    ToggleTables(value) {
      this.toggleTables = value
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  }
}
</script>
