<template>
  <div>
    <b-row align-h="between">
      <b-col md="3">
        <b-form-group label="تاريخ البداية">
          <b-form-datepicker
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="ar"
            size="lg"
            v-model="startDate"
            placeholder=""
            :rtl="direction"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="تاريخ النهاية">
          <b-form-datepicker
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="ar"
            size="lg"
            v-model="endDate"
            placeholder=""
            :rtl="direction"
          />
        </b-form-group>
      </b-col>

      <b-col align-self="center" class="d-flex justify-content-end pt-1 mb-1">
        <b-button variant="primary" @click="getInvoicesFromGrid">
          <feather-icon icon="SearchIcon" size="16" />
        </b-button>
      </b-col>
    </b-row>
    <b-skeleton-table v-if="loading" :rows="2" :columns="9" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>

    <vue-good-table
      v-else
      :columns="columns"
      :rows="this.invoices"
      :rtl="direction"
      :lineNumbers="true"
      :paginationOptions="{
        enabled: true,
        perPage: pageSize
      }"
      :sortOptions="{
        enabled: true
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label == 'التفاصيل'">
          <span>التفاصيل</span>
          <button class="btn btn-primary ml-2" style="padding: 4px" @click="DownloadData()">
            <feather-icon icon="FileTextIcon" size="18" />

            طباعة تقرير كامل
          </button>
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'noticeItemsTotal'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceItemsTotal'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'unTaxInvoiceItemsTotal'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceTax14Total'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>

        <span v-else-if="props.column.field === 'invoiceTax10Total'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceTaxTransport'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceStatus'">
          <b-badge :variant="props.formattedRow[props.column.field] === 2 ? 'success' : 'info'" class="text-capitalize">
            {{ props.formattedRow[props.column.field] === 2 ? 'تم التصديق' : 'تم المراجعة' }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'date'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'action'" class="text-nowrap">
          <div class="d-flex justify-content-center">
            <button class="btn btn-success mr-1" @click="openInvoiceDocument(props.row.id)">
              <feather-icon icon="PrinterIcon" size="16" />
            </button>
            <b-button
              variant="info"
              v-if="props.row.invoiceStatus === 1"
              class="mr-1"
              :disabled="invoiceReviewloading[props.row.id]"
              @click="reviewInvoice(props.row)"
            >
              <feather-icon v-if="!invoiceReviewloading[props.row.id]" icon="CheckIcon" size="16" />
              <b-spinner v-else small></b-spinner>
            </b-button>
            <b-button variant="danger" v-if="props.row.invoiceStatus === 1" class="mr-1" @click="rejectInvoice(props.row)">
              <feather-icon v-if="!invoiceReviewloading[props.row.id]" icon="XIcon" size="16" />
              <b-spinner v-else small></b-spinner>
            </b-button>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageSize" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModel from '@core/components/DeleteModel.vue'
import vSelect from 'vue-select'
import store from '@/store'
import * as XLSX from 'xlsx'

import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BRow,
  BCol,
  BSkeletonTable,
  BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BAvatar,
    BBadge,
    BModal,
    BCardCode,
    DeleteModel,
    VBModal,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    VueGoodTable,
    BRow,
    BCol,
    vSelect,
    BSkeletonTable,
    BSpinner
  },
  props: {
    companyName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: true,
      invoiceReviewloading: {},
      doneReview: {},

      isEditMode: false,
      pageSize: 7,
      pageNumber: 1,
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
      endDate: new Date().toISOString(),

      selectedCompany: null,
      jsonData: null,

      dir: false,
      columns: [
        {
          label: 'رقم الفاتورة',
          field: 'invoiceNumber',
          sortable: true
        },
        {
          label: 'التاريخ',
          field: 'date',
          sortable: true
        },
        {
          label: 'ضريبة 10%',
          field: 'invoiceTax10Total',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'ضريبة 14%',
          field: 'invoiceTax14Total',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'ضريبة النقل',
          field: 'invoiceTaxTransport',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'مجموع الاشعار',
          field: 'noticeItemsTotal',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'مجموع الفاتورة',
          field: 'invoiceItemsTotal',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'مجموع فاتورة بدون ضريبة',
          field: 'unTaxInvoiceItemsTotal',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'حالة الفاتورة',
          field: 'invoiceStatus',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'التفاصيل',
          field: 'action',
          sortable: false
        }
      ]
    }
  },
  async mounted() {
    await this.getInvoicesFromGrid()
    this.loading = false
  },

  methods: {
    ...mapActions('invoices', ['fetchInvoicesCompanyGrid', 'openInvoiceDocument', 'changeInvoiceStatusToApproved', 'changeInvoiceStatusToReject']),

    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EGP' }).format(value)
    },
    //format date
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    },
    async reviewInvoice(invoice) {
      this.$set(this.invoiceReviewloading, invoice.id, true)

      await this.changeInvoiceStatusToApproved(invoice.id)
        .then(() => {
          this.$set(this.invoiceReviewloading, invoice.id, false)
          this.$emit('update-invoice-status', invoice)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تغير حالة الفاتورة بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$set(this.invoiceReviewloading, invoice.id, false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء تغير حالة الفاتورة',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },
    // reject invoice
    async rejectInvoice(invoice) {
      this.$set(this.invoiceReviewloading, invoice.id, true)

      await this.changeInvoiceStatusToReject(invoice.id)
        .then(() => {
          this.$set(this.invoiceReviewloading, invoice.id, false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تغير حالة الفاتورة بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$set(this.invoiceReviewloading, invoice.id, false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء تغير حالة الفاتورة',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },

    async getInvoicesFromGrid() {
      const model = {
        StartDate: this.startDate ? new Date(this.startDate) : null,
        EndDate: this.endDate ? new Date(this.endDate) : null,
        Page: this.pageNumber,
        PageSize: this.pageSize,
        CompanyId: this.$route.params.id
      }
      await this.fetchInvoicesCompanyGrid(model)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تحميل البيانات بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء تحميل البيانات',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },

    async handlePageChange() {
      //
      await this.getInvoicesFromGrid()
    },

    async onPerPageChange(newPerPage) {
      this.pageSize = parseInt(newPerPage, 10)
      await this.getInvoicesFromGrid()
    },
    async DownloadData() {
      // Filter invoices to include only the keys present in this.columns
      const filteredInvoices = this.invoices.map((invoice) => {
        const filteredInvoice = {}
        this.columns.forEach((column) => {
          // Assuming column.key is the key you want to include
          if (column.field === 'date') {
            filteredInvoice[column.label] = this.formatDate(invoice[column.field])
          } else if (
            column.field === 'invoiceItemsTotal' ||
            column.field === 'invoiceTax14Total' ||
            column.field === 'invoiceTax10Total' ||
            column.field === 'noticeItemsTotal' ||
            column.field === 'invoiceTaxTransport'
          ) {
            filteredInvoice[column.label] = this.formatCurrency(invoice[column.field])
          } else if (column.field === 'invoiceStatus') {
            if (invoice[column.field] == 1) {
              filteredInvoice[column.label] = 'تم المراجعة'
            } else if (invoice[column.field] == 2) {
              filteredInvoice[column.label] = 'تم التصديق'
            }
          } else if (invoice.hasOwnProperty(column.field)) {
            filteredInvoice[column.label] = invoice[column.field]
          }
        })
        return filteredInvoice
      })

      const ws = XLSX.utils.json_to_sheet(filteredInvoices)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'تقرير-' + this.companyName + '.xlsx')
    }
    //add toster when delete product is done and if not done show error message
  },
  computed: {
    ...mapGetters('invoices', {
      invoices: 'getInvoices',
      totalCount: 'totalCountData',
      pagesCount: 'pagesCount'
    }),

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style scoped>
::v-deep .dropdown-menu {
  width: 270px;
}

::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep .dropdown-menu {
  width: 270px;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
/* search */
::v-deep .vs__search {
  color: #6e6b7b;
}
/* Add your custom styles here */
::v-deep table.vgt-table td {
  vertical-align: middle;
  color: #6e6b7b;
}
</style>
