<template>
  <b-card>
    <b-row>
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <AddToAccount ref="addToAccount" @add-payment="updateAccount" />
              <button style="margin-top: -80px" class="btn btn-primary" @click="showAddToAccountModal">إضافة دفع</button>
              <!-- <button
      style="margin-top: -80px; margin-right:3vh "
      class="btn btn-primary"
      @click="AddBill(company.id)"
    >
      إضافة فاتورة
    </button> -->
              <button style="margin-top: -10px; margin-right: 1vh" class="btn btn-primary" @click="toggle((payment = true))">المدفوعات</button>
              <button style="margin-top: -10px; margin-right: 1vh" class="btn btn-primary" @click="toggle((payment = false))">الفواتير</button>
            </div>
          </div>
        </div>
      </b-col>

      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th style="width: 150px" class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">الاسم</span>
            </th>
            <td class="pb-50">
              {{ this.company.userDisplayName }}
            </td>
          </tr>
          <tr>
            <th style="width: 150px" class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">الحساب</span>
            </th>
            <td class="pb-50">
              {{ this.company.account }}
            </td>
          </tr>
          <tr>
            <th style="width: 150px" class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">مجموع الفواتير</span>
            </th>
            <td class="pb-50">
              {{ this.company.totalSumInvoices }}
            </td>
          </tr>
          <tr>
            <th style="width: 150px" class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">مجموع المدفوعات</span>
            </th>
            <td class="pb-50">
              {{ this.company.totalSumPayments }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AddToAccount from './add-to-account.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    AddToAccount
  },
  props: ['id', 'selectedInvoice'],
  mounted() {
    this.GetCompany()
  },
  data() {
    return {
      company: {},
      payment: false
    }
  },
  methods: {
    updateAccount(price) {
      this.company.account = this.company.account + price
      this.company.totalSumPayments = this.company.totalSumPayments + price
    },
    showAddToAccountModal() {
      this.$refs.addToAccount.showModal()
    },
    async GetCompany() {
      try {
        const response = await this.$store.dispatch('GetCompany', this.$route.params.id)
        if ((response.status = 200)) {
          this.company = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    AddPayment(id) {
      this.$router.push({
        name: 'add-to-account',
        params: { id: id }
      })
    },
    AddBill(id) {
      this.$router.push({
        name: 'add-bill',
        params: { id: id }
      })
    },
    toggle(payment) {
      this.$emit('toggle', payment)
    }
  },
  watch: {
    selectedInvoice() {
      this.company.account = this.company.account - this.selectedInvoice.total
      this.company.totalSumInvoices = this.company.totalSumInvoices + this.selectedInvoice.total
    }
  }
}
</script>

<style></style>
